<template>
  <div class="lesson-list">
    <template v-if="courseType == 0">
      <div v-for=" (item, index) in list" :key="index">
        <course-lesson-item class="course-lesson" :unlocked="unlocked" :itemName="item.LessonName" :itemTeacher="item.LessonTeacher" :itemDuration="item.LessonDuration" :itemFreeVideo="item.LessonFreeVideo" :playing="active == item" @play-click="onPlayClick(item)" @unlock-click="onUnlockClick(item)"></course-lesson-item>
        <div class="course-line"></div>
      </div>
    </template>
    <template v-else-if="courseType == 1">
      <div v-for="courseInfo in list" class="course-lesson-box">
        <el-tooltip :content="courseInfo.CourseName" :open-delay="400" placement="top">
          <div class="course-name">{{ courseInfo.CourseName }}</div>
        </el-tooltip>
        <div v-for=" (item, index) in courseInfo.CourseLessonList" :key="index">
          <course-lesson-item class="course-lesson" :unlocked="unlocked" :itemName="item.LessonName" :itemTeacher="item.LessonTeacher" :itemDuration="item.LessonDuration" :itemFreeVideo="item.LessonFreeVideo" :playing="active == item" @play-click="onPlayClick(item)" @unlock-click="onUnlockClick(item)"></course-lesson-item>
          <div class="course-line"></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CourseLessonItem from "@/components/PC/CourseLessonItem";
export default {
  name: "CourseLessonList",
  components: {
    CourseLessonItem,
  },
  props: {
    courseType: {
      type: Number,
      default: 0,
    },
    active: {
      type: Object,
      default: function () {
        return null;
      },
    },
    unlocked: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {};
  },
  methods: {
    onPlayClick(item) {
      this.$emit("play-click", item);
    },
    onUnlockClick(item) {
      this.$emit("unlock-click", item);
    },
  },
  mounted() { },
};
</script>

<style scoped lang="less">
.lesson-list {
  display: flex;
  flex-direction: column;
}

.course-lesson-box {
  margin-bottom: 40px;

  .course-name {
    font-size: 18px;
    margin: 10px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .course-lesson {
    padding: 10px 0;
  }

  .course-lesson:hover {
    background-color: rgba(224, 224, 224, 0.2);
  }

  .course-line {
    border-bottom: 1px solid rgba(224, 224, 224, 0.5);
    margin: 0 10px;
  }
}
</style>