<template>
  <div class="item-box">
    <div class="item-left">
      <div class="row1">
        <span class="img">
          <i v-if="playing" class="el-icon-caret-right"></i>
        </span>
        <span :class="playing ? 'title playing' : 'title'">{{ itemName }}</span>
      </div>
      <div class="row2">
        <van-image class="teacher-img" :src="require('@/assets/images/comm/video.png')"></van-image>
        <span class="teacher">{{ itemTeacher }}</span>
        <span class="teacher">{{ itemDuration }}</span>
      </div>
    </div>
    <div class="item-right">
      <van-tag v-if="playing" type="warning" plain round>播放中</van-tag>
      <van-button v-else-if="unlocked" type="danger" size="mini" icon="play" @click="onPlayClick">播放</van-button>
      <van-button v-else-if="itemFreeVideo" type="danger" size="mini" icon="play" @click="onPlayClick">试看</van-button>
      <van-icon v-else name="lock" @click="onUnlockClick"></van-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseLessonItem",
  props: {
    playing: {
      type: Boolean,
      default: false,
    },
    unlocked: {
      type: Boolean,
      default: false,
    },
    itemName: {
      type: String,
      default: "--",
    },
    itemTeacher: {
      type: String,
      default: "--",
    },
    itemDuration: {
      type: String,
      default: "--",
    },
    itemFreeVideo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    onPlayClick() {
      this.$emit("play-click");
    },
    onUnlockClick() {
      this.$emit("unlock-click");
    },
  },
  mounted() { },
};
</script>

<style scoped lang="less">
.item-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-left {
  flex: 1;
  min-width: 0;

  .row1 {
    display: flex;
    align-items: center;
    margin: 5px 0;

    .img {
      min-width: 20px;
      max-width: 20px;
      color: #fe5e03;
      font-size: 20px;
    }

    .title {
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .playing {
      color: #fe5e03;
    }
  }

  .row2 {
    display: flex;
    align-items: center;
    margin: 10px 0 10px 50px;

    .teacher-img {
      flex-shrink: 0;
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }

    .teacher {
      color: gray;
      font-size: 13px;
      margin-right: 20px;
      white-space: nowrap;
    }
  }
}

.item-right {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}
</style>