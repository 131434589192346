<template>
  <div id="dplayer" class="player"></div>
</template>

<script>
import DPlayer from 'dplayer';

export default {
  name: "VideoPlayer",
  components: {

  },
  props: {
    url: {
      type: String,
      default: '',
    },
  },
  computed: {

  },
  data() {
    return {
      dp: null,
    };
  },
  methods: {
    init(pic, url) {
      this.dp = new DPlayer({
        container: document.getElementById('dplayer'),
        theme: "#b7daff", // 风格颜色，例如播放条，音量条的颜色
        lang: "zh-cn", // 语言，'en', 'zh-cn', 'zh-tw'
        preload: "auto", // 自动预加载
        volume: 0.5, // 初始化音量
        playbackSpeed: [0.5, 0.75, 1, 1.25, 1.5, 2, 3], //可选的播放速度
        mutex: false, //  防止同时播放多个用户，在该用户开始播放时暂停其他用户
        loop: false, // 是否自动循环
        screenshot: false, // 是否允许截图（按钮），点击可以自动将截图下载到本地
        hotkey: false, // 是否支持热键，调节音量，播放，暂停等
        contextmenu: [], //右键菜单
        video: {
          url: url,//视频地址
          pic: pic,//封面
        },
      });
      this.dp.contextmenu.destroy(); // 销毁右键菜单
    },
    play() {
      this.dp.pause();
    },
    pause() {
      this.dp.pause();
    },
    replay(url) {
      this.dp.switchVideo({
        url: url
      });
      this.dp.play();
    },
  },
  mounted() {

  }
};
</script>
<style scoped lang="less">
.player {
  width: 100%;
  height: 100%;
  background-color: black;
}

/*隐藏洗脑循环*/
::v-deep .dplayer-setting-loop {
  display: none;
}
</style>